.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-table table {
	font-size: 16px;
	text-align: center;
}

.ant-tag-success {
	font-size: 16px;
}
.ant-tag-error {
	font-size: 16px;
}
.ant-tag-warning {
	font-size: 16px;
}

.ant-table-thead .ant-table-cell {
	background-color: #4096ff !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
	text-align: center;
	color: white;
}

.amplify-button--primary {
	--amplify-internal-button-border-width: var(--amplify-components-button-primary-border-width);
	--amplify-internal-button-background-color: hsl(235, 71%, 65%);
	--amplify-internal-button-hover-background-color: hsl(235, 71%, 65%);
	--amplify-internal-button-border-color: var(--amplify-components-button-primary-border-color);
	--amplify-internal-button-color: var(--amplify-components-button-primary-color);
	--amplify-internal-button-disabled-border-color: var(
		--amplify-components-button-primary-disabled-border-color
	);
	--amplify-internal-button-disabled-background-color: var(
		--amplify-components-button-primary-disabled-background-color
	);
	--amplify-internal-button-disabled-color: var(--amplify-components-button-primary-disabled-color);
	--amplify-internal-button-loading-background-color: var(
		--amplify-components-button-primary-loading-background-color
	);
	--amplify-internal-button-loading-border-color: var(
		--amplify-components-button-primary-loading-border-color
	);
	--amplify-internal-button-loading-color: var(--amplify-components-button-primary-loading-color);
}

.highlighted-row {
	background-color: #eaeaea; /* Light yellow background for highlighting */
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-input-number-group
	> .ant-input-number:not(:first-child):not(:last-child) {
	border-radius: 0;
	width: 50px;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-input-number-group-wrapper {
	padding: 0px 10px;
}

:where(.css-dev-only-do-not-override-vsll6t).ant-card .ant-card-head {
	font-size: 24px;
}

:where(.css-dev-only-do-not-override-vsll6t).ant-btn.ant-btn-icon-only .anticon {
	font-size: 24px;
}

:where(.css-dev-only-do-not-override-vsll6t).ant-layout-header {
	height: unset;
}
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper .ant-table-tbody > tr > th,
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper .ant-table-tbody > tr > td,
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-vsll6t).ant-table-wrapper tfoot > tr > td {
	padding: 8px;
}
/* CSS to always show scrollbars */
.table-container {
	overflow: scroll; /* Ensure both scrollbars are always visible */
	width: 100%;
	height: calc(100vh - 265px); /* Adjust as needed */
	box-sizing: border-box; /* Ensure padding and border are included in the element’s total width and height */
	white-space: nowrap; /* Prevent table from wrapping to fit inside the container */
}

/* For webkit-based browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
	width: 6px; /* Set the width of the vertical scrollbar */
	height: 6px; /* Set the height of the horizontal scrollbar */
}

.table-container::-webkit-scrollbar-track {
	background: #f1f1f1; /* Track color */
}

.table-container::-webkit-scrollbar-thumb {
	background: #888; /* Scrollbar color */
}

.table-container::-webkit-scrollbar-thumb:hover {
	background: #555; /* Scrollbar color on hover */
}

.ant-table-thead {
	position: sticky;
	top: 0;
	background: #fff; /* Ensure this matches your table's design */
	z-index: 10; /* Ensure this is high enough to stay above other content */
}
:where(.css-dev-only-do-not-override-vsll6t).ant-layout-footer {
	padding: 10px;
	text-align: center;
}
